* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  padding: 10px 0;
}

html, body {
  height: 100%;
  overflow: hidden; /* 防止滚动条出现 */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.chat {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
  align-items: center;
  overflow: auto; /* 防止滚动条出现 */
}

.chat-window {
  padding: 10px 10px 10px 0px;
  flex: 1; /* 动态调整高度 */
  width: 800px; /* 固定宽度 */
  margin: 0 auto;
  overflow-y: auto;
  background-color: #ffffff; 
  border-radius: 10px;
  border: none;
}

.chat-window::-webkit-scrollbar {
  display: none; /* 完全隐藏滚动条 */
}

.chat-window:hover::-webkit-scrollbar {
  width: 8px;
}

.chat-window::-webkit-scrollbar-track {
  background: transparent;
}

.chat-window::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.user-message {
  display: inline-block;
  max-width: 80%;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 0px;
  border-radius: 10px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.gpt-message {
  display: inline-block;
  max-width: 80%;
  padding: 0px 10px 10px 5px;
  margin: 10px 10px 10px 0;
  border-radius: 10px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.user-message {
  background-color: #d1e7dd;
  align-self: flex-end;
  float: right;
  clear: both;
}

.gpt-message {
  background-color: transparent; /* 移除背景颜色 */
  align-self: flex-start;
  float: left;
  clear: both;
}

.gpt-message-container {
  display: flex;
  align-items: flex-start; /* 顶部对齐 */
}

.gpt-avatar {
  margin-right: 20px; /* 设置头像与文本之间的间距 */
  align-self: flex-start; /* 顶部对齐 */
}

.gpt-text {
  max-width: calc(100% - 34px); /* 考虑头像宽度，调整文本最大宽度 */
  display: inline-block; /* 确保文字与头像对齐 */
  vertical-align: top; /* 确保文字与头像顶部对齐 */
}

/* Markdown Styles */
.gpt-message table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.gpt-message table, 
.gpt-message th, 
.gpt-message td {
  border: 1px solid #ddd;
}

.gpt-message th, 
.gpt-message td {
  padding: 8px;
  text-align: left;
}

.gpt-message th {
  background-color: #f2f2f2;
}

.gpt-message tr:nth-child(even) {
  background-color: #f9f9f9;
}

.gpt-message h1,
.gpt-message h2,
.gpt-message h3,
.gpt-message h4,
.gpt-message h5,
.gpt-message h6 {
  line-height: 1; /* 设置行高 */
}

ul {
  list-style-position: inside;
  margin: -20px 0px; 
  padding: 0px;
}
.gpt-message ol {
  margin: -20px 0px -20px 0px;
  padding: 0px;
  text-align: left;
  position: relative;
  padding-left: 1.5em;
}

.gpt-message li {
  margin: -10px 0; 
  padding: 0px;
  text-align: left;
  position: relative;
}

/* .gpt-message li::marker {
  position: absolute;
  left: 0;
  top: 0;
} */

.gpt-message li p {
  margin: 0px 0px -20px 0px; /* 移除 <p> 元素的默认外边距 */
  padding: 0px;
  position: absolute;
  top: 0;
  /* left: 1.5em; */
}

/* .gpt-message ol li,ul li{
  list-style-position:inside;
  } */
  

/* .gpt-message li::before {
  content: '•'; /* 可以根据需要调整标记内容 */
  /* position: absolute;
  left: 0;
  top: 0;
} */ 

.gpt-message pre {
  background-color: #f6f8fa;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}

.gpt-message code {
  background-color: #f6f8fa;
  padding: 2px 5px;
  border-radius: 3px;
}

/* Chat Input Styles */
.chat-input-container {
  width: 800px; /* 固定宽度 */
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #ececec; /* 背景颜色 */
  border-radius: 8px;
  position: relative; /* 确保按钮绝对定位基于容器 */
  padding-right: 80px; /* 添加右边内边距，确保按钮不会与输入框内容重叠 */
}

textarea {
  flex: 1;
  padding: 15px 17px; /* 调整padding以使光标居中 */
  border: none; /* 隐藏边框 */
  background-color: #ececec; /* 背景颜色 */
  border-radius: 8px;
  resize: none;
  height: 50px; /* 设置初始高度为50px */
  font-size: 16px; /* 设置字体大小 */
  line-height: 20px; /* 调整line-height以使文字和光标居中 */
  overflow-y: scroll; /* 显示滚动条 */
  width: calc(100% - 60px); /* 确保textarea不会与按钮重叠 */
}

textarea:focus {
  border: none; /* 隐藏聚焦时的边框 */
  outline: none; /* 移除默认的聚焦外框 */
}

textarea::-webkit-scrollbar {
  width: 6px; /* 完全隐藏滚动条 */
}

textarea:hover::-webkit-scrollbar {
  width: 6px; /* 鼠标悬停时显示滚动条 */
}

textarea::-webkit-scrollbar-track {
  background: transparent;
}

textarea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.chat-input-button {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  position: absolute; /* 固定按钮位置 */
  bottom: -3px; /* 按钮与textarea底部对齐 */
  right: -5px; /* 距离右边10px */
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  height: 35px; /* 固定按钮高度 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  background-color: #0056b3;
}


button:disabled {
  background-color: #c0c0c0; /* 置灰背景色 */
  color: #fff; /* 确保禁用状态下文字仍为白色 */
  cursor: not-allowed; /* 禁用状态下的鼠标样式 */
  opacity: 0.6; /* 调整透明度以显示禁用效果 */
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  main {
    width: 100%; 
    padding: 10px 0px;
  }

  .chat {
    height: 100%;
    width: 100%; 
  }

  .gpt-avatar {
    margin-right: 15px; /* 设置头像与文本之间的间距 */
    align-self: flex-start; /* 顶部对齐 */
  }

  .container {
    width: 100%; /* 占据整个宽度 */
    height: 100%; /* 动态调整高度，保留输入框和按钮的空间 */
    padding: 15px 0px 15px 10px;
  }

  .user-message {
    max-width: 85%;
    margin: 0px 2px 0px 0px;
  }

  .gpt-message {
    max-width: 97%;
    margin: 30px 0px 0px -4px;
  }

  .chat-window {
    width: 100%; /* 占据整个宽度 */
    flex: none;
    height: 74vh;
     /* 动态调整高度，保留输入框和按钮的空间 */
    margin: 0px 0px 50px 0px; 
    overflow: auto; /* 防止滚动条出现 */
  }

  .chat-input-container {
    width: 97%; /* 占据整个宽度，减去左右各5px的间距 */
    margin: 0 auto;
    display: flex;
    align-items: center;
    background-color: #ececec; /* 背景颜色 */
    border-radius: 8px;
    padding-right: 80px; /* 添加右边内边距，确保按钮不会与输入框内容重叠 */
    position: fixed; /* 固定在视口 */
    bottom: 8px; /* 距离底部10px */
    left: 50%;
    transform: translateX(-50%); /* 水平居中 */
    z-index: 10; /* 确保输入框在最上层 */
  }
  
  textarea {
    flex: 1;
    padding: 15px 17px; /* 调整padding以使光标居中 */
    border: none; /* 隐藏边框 */
    background-color: #ececec; /* 背景颜色 */
    border-radius: 8px;
    resize: none;
    height: 50px; /* 设置初始高度为50px */
    font-size: 16px; /* 设置字体大小 */
    line-height: 20px; /* 调整line-height以使文字和光标居中 */
    overflow-y: scroll; /* 显示滚动条 */
    width: calc(100% - 60px); /* 确保textarea不会与按钮重叠 */
  }
    
  .chat-input-button {
    padding: 8px 12px;
    height: 50px; /* 固定按钮高度 */
    border: none;
    cursor: pointer;
    position: absolute; /* 固定按钮位置 */
    bottom: 0px; /* 按钮与textarea底部对齐 */
    right: -5px; /* 距离右边10px */
  }
  
  textarea:focus {
    border: none; /* 隐藏聚焦时的边框 */
    outline: none; /* 移除默认的聚焦外框 */
  }
  
  .textarea::-webkit-scrollbar {
    width: 6px; /* 完全隐藏滚动条 */
  }
  
  textarea:hover::-webkit-scrollbar {
    width: 6px; /* 鼠标悬停时显示滚动条 */
  }
  
  textarea::-webkit-scrollbar-track {
    background: transparent;
  }
  
  textarea::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .chat-window::-webkit-scrollbar {
    display: inline; /* 完全隐藏滚动条 */
  }
  
  .chat-window:hover::-webkit-scrollbar {
    width: 8px;
  }
  
  .chat-window::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .chat-window::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
}